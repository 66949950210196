<template>
  <section class="index-reviews">
    <div class="index-reviews__container container">
      <h2 class="index-reviews__title" v-t="'index.reviews.title'"/>
<!--      <iframe-->
<!--          src="https://revain.org/widgets-v3/carousel?reviewTargetSlug=projects%2Froobee&consumer=https%3A%2F%2Froobee.io%2F&contentLines=4&boxShadow=elevation1&writeReviewAt=5&sortBy=recent&perPage=3&bg=%23fcfcfc&reviewLocale=en&showRatingCard=true&showMoreLinkColor=%23b3f9ba"-->
<!--          width="100%"-->
<!--          height="545px"-->
<!--          style="border: none; display: block"-->
<!--      />-->
      <iframe src="https://widgets.revain.org/carousel?sortBy=rating&perPage=12&bg=transparent&contentLines=4&writeReviewAt=5&reviewLocale=en&showRatingCard=false&boxShadow=elevation3&showMoreLinkColor=%231992d4&disableTelemetry=false&showSignUpHeader=false&reviewTargetSlug=projects%2Froobee&consumer=roobee.io" width="100%" height="500" style="border: none; display: block">
              </iframe>
      <!--      <div class="index-reviews__slider">-->
      <!--        <div v-swiper:mySwiper="swiperOption">-->
      <!--          <div class="swiper-wrapper">-->
      <!--            <div class="swiper-slide index-reviews__slide" :key="i" v-for="(review, i) in reviews">-->
      <!--              <p class="text">{{ review.text[locale.toLowerCase()] }}</p>-->
      <!--              <div class="author-wrapper">-->
      <!--                <div class="author">-->
      <!--                  <img :src="review.avatar" :alt="review.author">-->
      <!--                  <span>{{ review.author }}</span>-->
      <!--                </div>-->
      <!--                <div class="link">-->
      <!--                  <a target="_blank" :href="review.link">-->
      <!--                    {{ review.short_link.replace('https://', '').replace('http://', '') }}-->
      <!--                  </a>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="index-reviews__slider-pagination slider-pagination"></div>-->
      <!--      </div>-->
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Reviews",

  props: ['reviews'],

  data() {
    return {
      swiperOption: {
        spaceBetween: 72,
        slidesPerView: 1,
        slidesPerGroup: 1,
        observer: true,
        observeParents: true,
        mousewheel: {
          forceToAxis: true
        },
        pagination: {
          el: '.index-reviews__slider-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          751: {
            slidesPerView: 'auto'
          }
        }
      }
    }
  },

  computed: {
    ...mapState(['locale']),
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/index/reviews';
</style>
